import { DeskData } from '@/datamodel/deskdata'
import { PopAlertModel, RoadMapSummaryData } from '@/datamodel/types'
import { Vue, Options } from 'vue-class-component'
import { Action, Getter } from 'vuex-class'
import RoadMapView from '@/components/roadmap/index.vue'
import { RoadMapData } from '@/components/roadmap/data-model'
import { DeskInfpParams } from '@/datamodel/urlparams/DeskInfoParams'
import { ResponseData } from '@/datamodel/response-data'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import { Watch } from 'vue-property-decorator'
import { CardInfoParams } from '@/datamodel/urlparams/CardInfoParams'
import { GameResult } from '@/datamodel/game-result'
import { ACT, EventBuss, GameRoundState } from '@/utils/constants'
import { Dictionary } from '@/datamodel/dictionary'
import { BetViewMixin } from '@/components/desk/betview/mixin'
import { Device } from '@/store/types'
import { VTextMarquee } from 'vue-text-marquee'
import { isLandscape, to24HoursFormat } from '@/utils'
import CryptoJS from 'crypto-js'

import screenfull, { Screenfull } from 'screenfull'
import { AxiosSource } from '@/api'
import axios, { CancelTokenSource } from 'axios'
import { useI18n } from 'vue-i18n'
import { BaseParams } from '@/datamodel/urlparams/BaseParams'
import { getVideoInformation } from '@/api/desk'

@Options({
    components: {
        RoadMapView,
        'marquee-text': VTextMarquee
    }
})
export class DeskMixin extends Vue {
    iN = useI18n().t
    messageCounter = 0
    messageInterval: number | boolean = false

    isLandscapeMode = isLandscape()

    showSwitcher = false
    showCustumChip = false
    showSettings = false
    showPasswordForm = false
    showHistory = false

    showLimitDialog = false

    isSuper6Mode = false
    hasConfirmedBet = false

    roadMapSummaryData: RoadMapSummaryData = {}
    nextRedRoadMapSummaryData: RoadMapSummaryData = {}
    nextBlueRoadMapSummaryData: RoadMapSummaryData = {}

    showSwicthTable = false

    deskResult = ''

    videoIsLoading = true
    isNoticeView = true
    noticeIsPaused = false

    currentCount = -1
    needReloadUI = true

    alertTimer: number | boolean = false
    hasPopAlert = false
    alertMessage: PopAlertModel | undefined

    countDownTimer: number | boolean = false
    serviceTimeOut: number | boolean = false
    dateTimeInterval: number | boolean = false
    dateTimeStr = to24HoursFormat(new Date())

    hasPendingRequest = false

    currentGameID: string | undefined
    needRefreshGameResult = true
    currentGameResult!: GameResult | undefined
    showPokerCards = false
    // showPokerCards = true

    allowBet = false

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    nodePlayer!: any
    videoPlayerCollection: Array<any> = new Array<any>()

    readonly PULL_INTERVAL = 2000
    readonly PULL_CARD_INTERVAL = 1500
    cardRequestTimeout: number | boolean = false
    failureCounter = 1

    videos: string[] = []
    videoRequestFailure = 0
    videoRequestTimeOut: boolean | number | any = false
    videoAxiosSource?: CancelTokenSource

    //#region Vuex
    @Getter('currentDesk') currentDesk!: DeskData
    @Getter('customChipValue') customChipValue!: number
    @Getter('chips') chips!: Array<number>
    @Getter('isFullScreen') isFullScreen!: boolean
    @Getter('deskNotice') deskNotice!: string
    @Getter('deskInfoKey') deskInfoKey!: number
    @Getter('userId') userId!: string
    @Getter('sessionId') sessionId!: string
    @Getter('cardResults') cardResults!: Dictionary<string>
    @Getter('currentChipIndex') chipIndex!: number
    @Getter('device') device!: Device

    @Action('setFullScreen') setFullScreen!: (isFull: boolean) => void
    @Action('setCurrentDesk') setCurrentDesk!: (desk: DeskData) => void
    @Action('destroySession') destroySession!: () => void
    @Action('showLoading') showLoading!: () => void
    @Action('hideLoading') hideLoading!: () => void
    @Action('setBetCoin') setBetCoin!: (betCoin: number) => void
    @Action('getDeskInfo') getDeskInfo!: (
        model: DeskInfpParams
    ) => Promise<ResponseData>

    @Action('getCardInfo') getCardInfo!: (
        model: CardInfoParams
    ) => Promise<string>
    @Action('setCardResultCache') setCardResultCache!: (
        cardResults: Dictionary<string>
    ) => void
    @Action('setCurrentChipIndex') setCurrentChipIndex!: (
        indexPosition: number
    ) => void

    @Action('setDeskInfoKey') setDeskInfoKey!: () => void
    //#endregion

    //#region Vue Methods
    mounted(): void {
        // begin monitoring the date and time
        this.dateTimeInterval = setInterval(() => {
            this.dateTimeStr = to24HoursFormat(new Date())
        }, 1000)

        // load the data
        this.loadData()

        // this.initVideoPlayer()

        // get video infos
        this.getVideoInfos()
    }

    beforeMount(): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.emitter.on(EventBuss.SHOW_POP_ALERT, this.showPopAlert)

        if (this.device.display === 1) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            this.emitter.on(
                EventBuss.ORIENTATION_CHANGE,
                this.OnOrientationChange
            )
        }
    }

    OnOrientationChange(): void {
        //
    }

    beforeUnmount(): void {
        // unregister events
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.emitter.off(EventBuss.SHOW_POP_ALERT, this.showPopAlert)

        if (this.device.display === 1) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            this.emitter.off(
                EventBuss.ORIENTATION_CHANGE,
                this.OnOrientationChange
            )
        }

        if (typeof this.dateTimeInterval === 'number') {
            clearInterval(this.dateTimeInterval)
            this.dateTimeInterval = false
        }

        // end service
        this.endService()
        this.stopRequestingCard()
        this.stopRequestingVideoInfos()
        // stop video
        this.disposeVideoPlayer()

        //stop timer countdown if its running
        this.stopCountDown()

        if (typeof this.messageInterval === 'number') {
            clearInterval(this.messageInterval)
            this.messageCounter = 0
        }
    }
    //#endregion
    //#region Helpers

    handleOnBetSuccess(): void {
        // console.log('betSuceess')
        this.hasConfirmedBet = true
    }
    stopRequestingCard() {
        this.clearAxiosRequest()
        if (typeof this.cardRequestTimeout === 'number') {
            clearTimeout(this.cardRequestTimeout)
            this.cardRequestTimeout = false
        }
    }

    clearAxiosRequest() {
        if (AxiosSource) {
            AxiosSource.Source.cancel()
            AxiosSource.Source = axios.CancelToken.source()
        }
    }

    handleOnPredict(value: number): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const roadmapView = this.$refs['roadmapview'] as RoadMapView
        roadmapView.Predict(value)
    }

    getChipImageWebp(amount: number): string {
        return require(`@/assets/imgs/chips${amount}.webp`)
    }

    getChipImageWebpPng(amount: number): string {
        return require(`@/assets/imgs/chips${amount}.png`)
    }
    getCustomChipValue(): unknown {
        return this.customChipValue <= 0 ? '自定义' : this.customChipValue
    }

    UpdateRoadMapData(
        data: RoadMapData,
        nextRed: RoadMapData,
        nextBlue: RoadMapData
    ): void {
        this.roadMapSummaryData = {
            gameType: this.currentDesk.gameType,
            data: data
        }

        this.nextRedRoadMapSummaryData = { data: nextRed }
        this.nextBlueRoadMapSummaryData = { data: nextBlue }
    }

    loadData(_forceReload = false): void {
        const model = new DeskInfpParams(
            this.currentDesk.deskId || -1,
            this.userId,
            this.sessionId
        )

        if (!_forceReload) {
            this.showLoading()
        }

        if (this.$route.name !== 'game') {
            return
        }

        this.getDeskInfo(model)
            .then((response: ResponseData) => {
                this.hideLoading()
                if (response.error == '2') {
                    // session is expired logut user and redirect it to login
                    this.endService()
                    this.destroySession()
                    this.$router.push({ name: 'login' })
                } else {
                    if (response.data) {
                        const desksData = DeskData.Parse(
                            response.data.split('&postionId')[0]
                        )
                        desksData.deskId = response.optinal
                        if (desksData) {
                            this.setBetCoin(desksData.balance)
                            this.setCurrentDesk(desksData)
                            if (
                                this.currentDesk.currentTime <= 0 &&
                                desksData.gameStatus !==
                                    GameRoundState.END_OF_ROUND
                            ) {
                                // call card request
                                this.cardRequestTimeout = setTimeout(() => {
                                    this.OnRecievedDeskInfo()
                                    // this.fetchCardResult()
                                }, this.PULL_CARD_INTERVAL)
                            } else {
                                // call desk info
                                this.serviceTimeOut = setTimeout(() => {
                                    this.loadData(true)
                                }, this.PULL_INTERVAL)
                            }

                            // 重置 failureCounter
                            this.failureCounter = 0
                        }
                    }
                }
            })
            .catch(e => {
                if (!axios.isCancel(e)) {
                    this.failureCounter += 1
                    if (_forceReload) {
                        this.hideLoading()
                    }

                    if (this.failureCounter > 3) {
                        this.endService()
                        this.destroySession()
                        this.$router.push({ name: 'login' })
                        return
                    }

                    this.serviceTimeOut = setTimeout(() => {
                        this.loadData(true)
                    }, this.PULL_INTERVAL)
                }
            })
    }

    endService(): void {
        this.clearAxiosRequest()
        if (typeof this.serviceTimeOut === 'number') {
            clearTimeout(this.serviceTimeOut)
            this.serviceTimeOut = false
        }
        this.stopRequestingCard()
    }
    startCountDown(): void {
        if (typeof this.countDownTimer !== 'number') {
            // Change Game Status
            // EventBus.$emit(EventBusEvents.START_BETTING)
            // Start betting here
            this.allowBet = true
            this.currentCount = this.currentDesk.currentTime
            if (this.currentCount > 20) {
                audioPlayer.Play(SOUNDKEY.START_BET)
            }

            this.countDownTimer = setInterval(() => {
                if (this.currentCount < 0) {
                    this.stopCountDown()
                    audioPlayer.Play(SOUNDKEY.STOP_BET)
                } else {
                    if (this.currentCount <= 11 && this.currentCount > 0) {
                        audioPlayer.Play(SOUNDKEY.TICK)
                    }
                    this.currentCount -= 1
                }
            }, 1000)
        }
    }
    stopCountDown(): void {
        if (typeof this.countDownTimer === 'number') {
            clearInterval(this.countDownTimer)
            this.countDownTimer = false
            this.currentCount = -1
            // notify  Change of game status here
            // EventBus.$emit(EventBusEvents.STOP_BETTING)
            // stop the betting here
            this.allowBet = false
            this.resetBetView()
        }
    }

    resetBetView(): void {
        const betview = this.$refs['betview'] as BetViewMixin
        if (betview) betview.resetBet()
    }

    hideGameResult(): void {
        const betview = this.$refs['betview'] as BetViewMixin
        if (betview) betview.hideGameResult()
    }

    showGameResult(): void {
        const betview = this.$refs['betview'] as BetViewMixin
        if (betview) betview.showResult(this.currentGameResult)
    }

    resetUI(data = ''): void {
        this.deskResult = data

        // check if super 6 mode has bet or banker super 6 mode has bet
        if (
            this.currentDesk.hasBetOnSuper6 ||
            this.currentDesk.hasBetOnRedSuper6
        )
            this.isSuper6Mode = true
        // if (this.currentDesk.hasBetOnSuper6 && !this.isSuper6Mode) {
        //     this.isSuper6Mode = true
        // }
    }

    OnRecievedDeskInfo(): void {
        if (
            this.currentGameID === undefined ||
            this.cardResults.ContainsKey(this.currentGameID)
        ) {
            if (this.currentGameID !== this.currentDesk.gameId) {
                // console.log('GameId has been Reset')
                this.currentGameID = this.currentDesk.gameId
            }
        }

        if (this.currentDesk.currentTime <= 0) {
            this.needRefreshGameResult = true
        }

        if (
            this.needRefreshGameResult &&
            this.currentGameID !== undefined &&
            !this.cardResults.ContainsKey(this.currentGameID)
        ) {
            this.fetchCardResult()
        } else {
            this.stopRequestingCard()
            this.loadData(true)
        }
    }

    OnRecievedCardInfo(dataStr: string): void {
        if (dataStr === '') return
        if (this.currentGameResult !== undefined) {
            if (this.currentGameResult.rawData === dataStr) {
                this.cardRequestTimeout = setTimeout(() => {
                    // this.OnRecievedDeskInfo()
                    this.fetchCardResult()
                }, this.PULL_CARD_INTERVAL)
                return
            }
        }
        if (
            this.currentGameID !== undefined &&
            this.cardResults.ContainsKey(this.currentGameID)
        ) {
            this.cardRequestTimeout = setTimeout(() => {
                // this.OnRecievedDeskInfo()
                this.fetchCardResult()
            }, this.PULL_CARD_INTERVAL)
            return
        }

        this.currentGameResult = new GameResult(
            dataStr,
            this.currentDesk.gameType || 1
        )
        if (
            this.currentGameResult.state === GameRoundState.END_OF_ROUND &&
            !this.currentGameResult.isNewShoe
        ) {
            this.cardResults.Add(
                this.currentGameID ?? '',
                this.currentGameResult.rawData
            )
            if (this.cardResults.Count() > 10) {
                this.cardResults.Remove(this.cardResults.Keys()[0])
            }
            this.setCardResultCache(this.cardResults)
            this.needRefreshGameResult = false

            // EventBus.$emit(
            //     EventBusEvents.DESK_DEAL_RESULT,
            //     this.currentGameResult
            // )

            // uncomment this later
            this.showPokerCards = true
            this.showGameResult()

            setTimeout(() => {
                this.showPokerCards = false
                this.hideGameResult()
            }, 6000)

            if (this.currentGameResult.won != 0) {
                const popError: PopAlertModel = {
                    type: 1,
                    header: '',
                    message: `${this.currentGameResult.won}`,
                    color: ''
                }

                if (this.currentGameResult.won > 0) {
                    popError.message = `+${this.currentGameResult.won}`
                }
                const winner = this.currentGameResult.whoWin

                if (this.currentGameResult.gameType === 1) {
                    if (winner === 1) {
                        popError.header = '庄赢'
                        popError.color = 'banker'
                    } else if (winner === 2) {
                        popError.header = '和局'
                        popError.color = 'tie'
                    } else {
                        popError.header = '闲赢'
                        popError.color = 'player'
                    }
                } else {
                    if (winner === 1) {
                        popError.header = '龙赢'
                        popError.color = 'banker'
                    } else if (winner === 2) {
                        popError.header = '和局'
                        popError.color = 'tie'
                    } else {
                        popError.header = '虎赢'
                        popError.color = 'player'
                    }
                }

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
            }
            this.stopRequestingCard()
            this.loadData(true)
            // 重置 failureCounter
            this.failureCounter = 0
        } else {
            this.cardRequestTimeout = setTimeout(() => {
                // this.OnRecievedDeskInfo()
                this.fetchCardResult()
            }, this.PULL_CARD_INTERVAL)
        }
    }

    fetchCardResult(): void {
        if (this.$route.name !== 'game') {
            return
        }

        const model = new CardInfoParams(this.userId, this.currentGameID || '')

        this.getCardInfo(model)
            .then((data: string) => {
                if (data.includes('err=2')) {
                    this.endService()
                    this.destroySession()
                    this.$router.push('/login')
                    return
                }

                this.OnRecievedCardInfo(data)
            })
            .catch(e => {
                if (!axios.isCancel(e)) {
                    this.failureCounter += 1
                    if (this.failureCounter > 3) {
                        this.endService()
                        this.destroySession()
                        this.$router.push({ name: 'login' })
                        return
                    }

                    this.cardRequestTimeout = setTimeout(() => {
                        this.fetchCardResult()
                    }, this.PULL_CARD_INTERVAL)
                }
            })
    }

    showPopAlert(model: PopAlertModel): void {
        this.alertMessage = model
        this.messageCounter = 9

        if (typeof this.messageInterval !== 'number') {
            this.messageInterval = setInterval(() => {
                this.messageCounter -= 1
                if (
                    this.messageCounter <= 0 &&
                    typeof this.messageInterval === 'number'
                ) {
                    clearInterval(this.messageInterval)
                    this.messageInterval = false
                }
            }, 500)
        }

        if (typeof this.alertTimer === 'number') {
            clearInterval(this.alertTimer)
            this.alertTimer = false
        }

        // this.alertMessage = model
        // this.hasPopAlert = true

        // this.alertTimer = setTimeout(() => {
        //     this.hasPopAlert = false
        //     this.alertMessage = undefined
        // }, 6000)
    }

    stopRequestingVideoInfos() {
        if (typeof this.videoAxiosSource !== 'undefined') {
            this.videoAxiosSource.cancel()
            this.videoAxiosSource = axios.CancelToken.source()
        }

        if (typeof this.videoRequestTimeOut === 'number') {
            clearTimeout(this.videoRequestTimeOut)
            this.videoRequestTimeOut = false
        }
    }

    getVideoInfos() {
        this.videos = []
        this.disposeVideoPlayer()

        // set request cancel token
        if (typeof this.videoAxiosSource === 'undefined')
            this.videoAxiosSource = axios.CancelToken.source()

        const _params = {
            ...new BaseParams(ACT.VIDEO_INFOS),
            sessionID: this.sessionId,
            desk: this.currentDesk.deskId || -1,
            username: this.userId
        }

        getVideoInformation(_params, this.videoAxiosSource)
            .then(response => {
                if (response.data !== '') {
                    this.videos = response.data.split('#')

                    // initPlayer()
                    this.initVideoPlayer()
                }
            })
            .catch(e => {
                if (!axios.isCancel(e)) {
                    // request video info again
                    this.videoRequestFailure += 1

                    if (this.videoRequestFailure > 3) {
                        this.stopRequestingVideoInfos()
                        this.endService()
                        this.destroySession()
                        this.$router.push({ name: 'login' })
                        return
                    }

                    this.videoRequestTimeOut = setTimeout(() => {
                        this.getVideoInfos()
                    }, 2000)
                }
            })
    }

    //#endregion
    //#region Video Player
    initVideoPlayer(): void {
        this.videoIsLoading = true
        this.disposeVideoPlayer()

        // const videoPaths = [
        //     process.env.VIDEO1_PATH,
        //     process.env.VIDEO2_PATH,
        //     process.env.VIDEO3_PATH,
        //     process.env.VIDEO4_PATH
        // ]
        let isVideoConnected = false

        this.videos.forEach(vPath => {
            // const videoname = 'kkvideokkw' + this.currentDesk.deskId

            // const timeStamp = new Date(
            //     new Date().getTime() + 0.4 * 60000
            // ).getTime()

            // const hashMD5 = CryptoJS.MD5(
            //     `/kkw/${videoname}-${timeStamp}-${process.env.VIDEO_KEY}`
            // )

            // const videoUrl = `${vPath}/kkw/${videoname}.flv?sign=${timeStamp}-${hashMD5.toString()}`

            const _player = new NodePlayer()
            _player.setVolume(0)
            _player.setView('videoplayer2')
            _player.setBufferTime(1000)
            _player.setScaleMode(2)
            _player.on('start', () => {
                if (!isVideoConnected) {
                    isVideoConnected = true
                    this.nodePlayer = _player
                    this.nodePlayer.clearView()
                    this.nodePlayer.setView('videoplayer1')
                    this.nodePlayer.on(
                        'buffer',
                        (evt: 'empty' | 'buffering' | 'full') => {
                            if (evt === 'empty' || evt === 'buffering') {
                                this.videoIsLoading = true
                            } else if (evt === 'full') {
                                this.videoIsLoading = false
                            }
                        }
                    )
                    this.nodePlayer.on('error', (e: any) => {
                        this.videoIsLoading = true
                        setTimeout(() => {
                            this.nodePlayer.clearView()
                            this.nodePlayer.stop()
                            this.initVideoPlayer()
                        }, 1000)
                    })
                } else {
                    _player.clearView()
                    _player.stop()
                }
            })
            _player.on('error', () => {
                //
            })
            this.videoPlayerCollection.push()
            // _player.start(videoUrl)
            _player.start(vPath)
        })
    }

    disposeVideoPlayer(): void {
        if (this.nodePlayer) {
            this.nodePlayer.stop()
            this.nodePlayer.clearView()
            this.nodePlayer = undefined
            this.videoIsLoading = true
        }

        this.videoPlayerCollection.forEach(_player => {
            if (_player) {
                _player.clearView()
                _player.stop()
            }
        })

        this.videoPlayerCollection = []
    }

    //#endregion
    //#region Click Events

    showChipDialog(isTrue: boolean): void {
        if (isTrue) {
            if (this.device.display !== 1) {
                this.showCustumChip = true
            } else {
                this.emitter.emit(EventBuss.SHOW_NUMERIC_KEYBOARD, true)
            }
        } else {
            if (this.customChipValue > 0) {
                if (this.chipIndex === 5) {
                    // this.chip = 0
                    this.setCurrentChipIndex(-1)
                } else {
                    this.setCurrentChipIndex(5)
                    // this.chip = this.customChipValue
                }
            } else {
                if (this.device.display !== 1) {
                    this.showCustumChip = true
                } else {
                    this.emitter.emit(EventBuss.SHOW_NUMERIC_KEYBOARD, true)
                }
            }
        }
    }

    ShowSwitchTable(): void {
        this.showSwicthTable = !this.showSwicthTable
    }
    OnCLickToggleFullScreen(): void {
        this.setFullScreen(!this.isFullScreen)
        if (!this.isFullScreen && screenfull.isEnabled) {
            const sc = screenfull as Screenfull
            sc.exit()
        }
    }

    chipClicked(index: number): void {
        if (index === this.chipIndex && this.chipIndex !== -1) {
            this.setCurrentChipIndex(-1)
            // this.chip = 0
            return
        }

        this.setCurrentChipIndex(index)
        // this.chip = amount
    }

    OnShowSwitchTable(): void {
        this.showSwicthTable = !this.showSwicthTable
    }

    onSwitchTable(desk: DeskData): void {
        if (typeof this.messageInterval === 'number') {
            clearInterval(this.messageInterval)
            this.messageCounter = 0
        }

        this.showSwicthTable = false
        this.endService()
        this.stopRequestingCard()
        this.stopRequestingVideoInfos()
        this.stopCountDown()

        // reset betting
        this.allowBet = false
        this.resetBetView()
        this.hideGameResult()

        this.needReloadUI = true
        this.resetUI()

        this.currentGameID = undefined
        this.setCurrentDesk(desk)
        this.showPokerCards = false
        this.videos = []
        this.videoIsLoading = true

        this.loadData()
        this.getVideoInfos()
        // this.initVideoPlayer()
    }

    OnClickCustumerService(): void {
        window.open(process.env.VUE_APP_CUSTUMER_SERVICE, '_blank')
    }

    OnClickRules(): void {
        window.open(
            'http://kkw6888.com/resource/rules/rule_home.html',
            '_blank'
        )
    }

    toggleSuper6Mode(isTrue: boolean): void {
        if (this.hasConfirmedBet) return

        this.isSuper6Mode = isTrue
    }
    //#endregion
    //#region
    //#region Getter
    get title(): string {
        if (this.currentDesk.gameType === 1) {
            return `百家乐 ${this.currentDesk.deskId} 号桌`
        } else {
            return `龙虎 ${this.currentDesk.deskId} 号桌`
        }
    }
    get deskGameId(): string {
        if (this.currentDesk && this.currentDesk.gameId) {
            return this.currentDesk.gameId
        }

        return ''
    }

    get redCount(): number {
        if (this.roadMapSummaryData.data)
            return this.roadMapSummaryData.data.redCount
        return 0
    }

    get blueCount(): number {
        if (this.roadMapSummaryData.data)
            return this.roadMapSummaryData.data.blueCount
        return 0
    }

    get greenCount(): number {
        if (this.roadMapSummaryData.data)
            return this.roadMapSummaryData.data.tieCount
        return 0
    }

    get redPairCount(): number {
        if (this.roadMapSummaryData.data)
            return this.roadMapSummaryData.data.redPairCount
        return 0
    }

    get bluePairCount(): number {
        if (this.roadMapSummaryData.data)
            return this.roadMapSummaryData.data.bluePairCount
        return 0
    }

    get timerColor(): string {
        if (this.currentCount > 20) {
            return 'green'
        } else if (this.currentCount <= 20 && this.currentCount > 10) {
            return 'orange'
        } else {
            return 'red'
        }
    }

    get chip(): number {
        if (this.chipIndex === 5) return this.customChipValue

        if (this.chipIndex === -1) return 0

        const amt = this.chips[this.chipIndex]
        if (amt) return amt
        return 0
    }

    get gameType(): number {
        if (this.currentDesk) return this.currentDesk.gameType || 1
        else return 1
    }

    //#endregion

    //#region Watcher

    @Watch('currentDesk', { immediate: true })
    OnDeskResult(desk: DeskData): void {
        if (desk && this.needReloadUI) {
            this.hasConfirmedBet = desk.hasBet
            this.resetUI(desk.result || '')
            if (desk.currentTime !== -1) {
                this.startCountDown()
            } else {
                if (typeof this.countDownTimer === 'number') {
                    this.stopCountDown()
                    audioPlayer.Play(SOUNDKEY.STOP_BET)
                }
                this.currentCount = -1
            }
        } else {
            this.needReloadUI = true
        }
    }

    @Watch('customChipValue', { immediate: false })
    WatchCustumChipChange(value: number): void {
        if (value > 0) this.setCurrentChipIndex(5)
    }
    //#endregion
}
