import { ACT } from '@/utils/constants'
import { BaseParams } from './BaseParams'

export class DeskInfpParams extends BaseParams {
    desk: number
    sessionID: string
    username: string

    /**
     *
     */
    constructor(desk: number, username: string, sessionId: string) {
        super(ACT.SINGLE_DESK_INFO)
        this.desk = desk
        this.username = username
        this.sessionID = sessionId
    }
}
