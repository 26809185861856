
import { Device } from '@/store/types'
import { Options, Vue } from 'vue-class-component'
import { Getter } from 'vuex-class'
import PcDesk from './d-desk.vue'
import MobileDesk from './m-desk.vue'

@Options({
    components: {
        PcDesk,
        MobileDesk
    }
})
export default class Loby extends Vue {
    //#region Vuex
    @Getter('device') device!: Device

    //#endregion
}
