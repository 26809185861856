import { ACT } from '@/utils/constants'
import { BaseParams } from './BaseParams'

export class CardInfoParams extends BaseParams {
    gameId: string
    username: string

    constructor(username: string, gameId: string) {
        super(ACT.CARD_INFO)
        this.username = username
        this.gameId = gameId
    }
}
