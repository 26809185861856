import { DeskData } from '@/datamodel/deskdata'
import { GameResult } from '@/datamodel/game-result'
import { GAME_TYPE } from '@/utils/constants'
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { BetViewBaseMixin } from './bet-view-mixin'
import PcBaccaratBetView from './d-baccarat-bet-view.vue'
import PcDragonTigerBetView from './d-dragon-tiger-bet-view.vue'

@Options({
    components: {
        PcBaccaratBetView,
        PcDragonTigerBetView
    }
})
export class BetViewMixin extends Vue {
    @Prop({ default: GAME_TYPE.BACCARAT })
    gameType!: number
    @Prop({ default: -1 })
    chipValue!: number
    @Prop({ default: false })
    allowBet!: boolean

    @Prop({ default: false })
    s6Mode!: boolean

    @Getter('currentDesk')
    currentDesk!: DeskData

    resetBet(): void {
        const bettingView = this.$refs['bettingview'] as BetViewBaseMixin
        if (bettingView) {
            bettingView.OnCancelBetTemp()
            bettingView.ResetConfirmedTemp()
        }
    }

    showResult(deskResult: GameResult | undefined): void {
        const bettingView = this.$refs['bettingview'] as BetViewBaseMixin
        if (bettingView) bettingView.showResult(deskResult)
    }

    hideGameResult(): void {
        const bettingView = this.$refs['bettingview'] as BetViewBaseMixin
        if (bettingView) bettingView.hideGameResult()
    }

    get redConfirmed(): number {
        if (this.gameType === GAME_TYPE.BACCARAT && this.currentDesk)
            return this.currentDesk.redBet

        if (this.gameType === GAME_TYPE.DRAGON_TIGER && this.currentDesk)
            return this.currentDesk.blueBet
        return 0
    }

    get blueConfirmed(): number {
        if (this.gameType === GAME_TYPE.BACCARAT && this.currentDesk)
            return this.currentDesk.blueBet

        if (this.gameType === GAME_TYPE.DRAGON_TIGER && this.currentDesk)
            return this.currentDesk.redBet
        return 0
    }

    get greenConfirmed(): number {
        if (this.currentDesk) return this.currentDesk.tieBet
        return 0
    }

    get bluePairConfirmed(): number {
        if (this.currentDesk) return this.currentDesk.bluePairBet
        return 0
    }

    get redPairConfirmed(): number {
        if (this.currentDesk) return this.currentDesk.redPairBet
        return 0
    }

    get super6Confirmed(): number {
        if (this.currentDesk) return this.currentDesk.super6Bet

        return 0
    }

    get redSuper6Confimed(): number {
        if (this.currentDesk) return this.currentDesk.redSuper6Bet
        return 0
    }

    @Watch('currentDesk', { deep: true })
    DeskDataChange(): void {
        const bettingView = this.$refs['bettingview'] as BetViewBaseMixin
        if (bettingView) bettingView.ResetConfirmedTemp()
    }
}
