
import { Options } from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import { BetAreaViewMixin } from './bet-area-mixin'

@Options({})
export default class MobileBetArea extends Mixins(BetAreaViewMixin) {
    @Prop({ default: true })
    showConfirmCancelButton!: boolean
}
