
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

import { BaccaratBetViewMixin } from './baccarat-bet-view-mixin'
import PcBetArea from './d-bet-area.vue'

@Options({
    components: {
        PcBetArea
    }
})
export default class PcBaccaratBetView extends Mixins(BaccaratBetViewMixin) {}
