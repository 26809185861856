
import { PopAlertModel } from '@/datamodel/types'
import { Device } from '@/store/types'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Options({})
export default class PopAlertView extends Vue {
    isMobile = false
    @Prop()
    mdata!: PopAlertModel

    @Getter('device') divice!: Device

    beforeMount(): void {
        this.isMobile = this.divice.display === 1
    }

    get showAlrtHeader(): boolean {
        if (!this.mdata) return false

        return this.mdata.type === 1
        // return true
    }

    get message(): string {
        if (!this.mdata) return ''

        return this.mdata.message
    }

    get header(): string {
        if (!this.mdata) return ''
        return this.mdata.header
    }

    get headerColor(): string {
        if (!this.mdata) return ''
        return this.mdata.color
    }
}
