
import { TableLimit } from '@/datamodel/types'
import { isLandscape, toMoneyFormat } from '@/utils'
import { Options } from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { DeskMixin } from './mixin'
import MobileBetView from '@/components/desk/betview/m-betview.vue'
import DPredictionSumaryView from '@/components/desk/prediction/d-prediction-sumary-view.vue'
import MobileSwitchTableView from '@/components/desk/switch-table/m-switch-table.vue'
import CustomMoneyView from '@/components/dialogs/custom-money-dialog-view.vue'
import LimitDialogView from '@/components/dialogs/limit-dialog-view.vue'
import MobileSettingDialogView from '@/components/dialogs/m-setting-dialog-view.vue'
import ChangePasswordView from '@/components/dialogs/change-pass-view.vue'
import BetHistory from '@/components/dialogs/history-view.vue'
import MobileCardResultView from '@/components/desk/card-result/m-card-result-view.vue'
import { GAME_TYPE } from '@/utils/constants'
import PopAlertView from '@/components/desk/pop-alert.vue'
import AppSvg from '@/components/app-svg.vue'

@Options({
    components: {
        MobileBetView,
        DPredictionSumaryView,
        MobileSwitchTableView,
        CustomMoneyView,
        LimitDialogView,
        MobileSettingDialogView,
        ChangePasswordView,
        BetHistory,
        'm-card-result-view': MobileCardResultView,
        'app-svg': AppSvg,
        PopAlertView
    }
})
export default class MobileDesk extends Mixins(DeskMixin) {
    roadMapKey = new Date().getTime()

    roadmapDisplay = isLandscape() ? 0 : 1

    // screenOrientation = require('screen-orientation')

    enterToLoby(): void {
        this.$router.push({ name: 'loby' })
    }

    OnOrientationChange(): void {
        this.isLandscapeMode = isLandscape()
        this.roadMapKey = new Date().getTime()

        setTimeout(() => {
            this.setDeskInfoKey()
            this.noticeIsPaused = false
        }, 1000)

        // alert(document.body.getBoundingClientRect().height)
    }

    @Getter('betCoin') betCoin!: number
    @Getter('deskLimit') deskLimit!: TableLimit

    get redLimit(): string {
        if (this.deskLimit)
            return `${this.deskLimit.Min} - ${this.deskLimit.Max}`
        return '0-0'
    }

    get gameType(): number {
        if (this.currentDesk)
            return this.currentDesk.gameType || GAME_TYPE.BACCARAT
        return GAME_TYPE.BACCARAT
    }

    @Watch('isLandscapeMode', { immediate: true })
    OnRoadmapDisplayModeChange(isTrue: boolean): void {
        this.roadmapDisplay = isTrue ? 0 : 1
    }
}
