import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pc_dragon_tiger_bet_view = _resolveComponent("pc-dragon-tiger-bet-view")
  const _component_pc_baccarat_bet_view = _resolveComponent("pc-baccarat-bet-view")

  return (_ctx.gameType === 2)
    ? (_openBlock(), _createBlock(_component_pc_dragon_tiger_bet_view, {
        key: 0,
        chipValue: _ctx.chipValue,
        allowBet: _ctx.allowBet,
        redConfirmed: _ctx.redConfirmed,
        blueConfrimed: _ctx.blueConfirmed,
        greenConfrimed: _ctx.greenConfirmed,
        onBetSuccess: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('bet-success'))),
        ref: "bettingview"
      }, null, 8, ["chipValue", "allowBet", "redConfirmed", "blueConfrimed", "greenConfrimed"]))
    : (_openBlock(), _createBlock(_component_pc_baccarat_bet_view, {
        key: 1,
        chipValue: _ctx.chipValue,
        s6Mode: _ctx.s6Mode,
        allowBet: _ctx.allowBet,
        redConfirmed: _ctx.redConfirmed,
        blueConfrimed: _ctx.blueConfirmed,
        greenConfrimed: _ctx.greenConfirmed,
        redPairConfirmed: _ctx.redPairConfirmed,
        bluePairConfirmed: _ctx.bluePairConfirmed,
        super6Confirmed: _ctx.super6Confirmed,
        redSuper6Confimed: _ctx.redSuper6Confimed,
        onBetSuccess: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('bet-success'))),
        ref: "bettingview"
      }, null, 8, ["chipValue", "s6Mode", "allowBet", "redConfirmed", "blueConfrimed", "greenConfrimed", "redPairConfirmed", "bluePairConfirmed", "super6Confirmed", "redSuper6Confimed"]))
}