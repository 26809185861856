
import { Mixins } from 'vue-property-decorator'
import { SwitchTableMixin } from './switch-table-mixin'
import DSwitchTableItem from './d-switch-table-item.vue'
import { Options } from 'vue-class-component'

@Options({
    components: {
        DSwitchTableItem
    }
})
export default class MobileSwitchTableView extends Mixins(SwitchTableMixin) {}
