
import { Mixins } from 'vue-property-decorator'
import { BetViewMixin } from './mixin'
import MobileBaccratBetView from './m-baccarat-bet-view.vue'
import MobileDragonTigerBetView from './m-dragon-tiger-bet-view.vue'
import { Options } from 'vue-class-component'
import { GAME_TYPE } from '@/utils/constants'

@Options({
    components: {
        MobileBaccratBetView,
        MobileDragonTigerBetView
    }
})
export default class MobileBetView extends Mixins(BetViewMixin) {
    hasUnconfirmedBet = false
    confirmBet(): void {
        if (this.gameType === GAME_TYPE.BACCARAT) {
            const view = this.$refs['bettingview'] as MobileBaccratBetView
            if (view) view.OnConfirmBet()
        } else if (this.gameType === GAME_TYPE.DRAGON_TIGER) {
            const view = this.$refs['bettingview'] as MobileDragonTigerBetView
            if (view) view.OnConfirmBet()
        }
    }

    cancelBet(): void {
        if (this.gameType === GAME_TYPE.BACCARAT) {
            const view = this.$refs['bettingview'] as MobileBaccratBetView
            if (view) view.OnCancelBetTemp()
        } else if (this.gameType === GAME_TYPE.DRAGON_TIGER) {
            const view = this.$refs['bettingview'] as MobileDragonTigerBetView
            if (view) view.OnCancelBetTemp()
        }
    }
    onBetChange(numValue: number): void {
        this.hasUnconfirmedBet = numValue > 0
    }
}
