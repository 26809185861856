
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { DeskMixin } from './mixin'

import HeaderView from '@/components/header/index.vue'
import PcBetView from '@/components/desk/betview/d-betview.vue'

import DPredictionSumaryView from '@/components/desk/prediction/d-prediction-sumary-view.vue'
import PcSwitchTable from '@/components/desk/switch-table/d-switch-table.vue'
import DCardResultView from '@/components/desk/card-result/d-card-result-view.vue'
import CustomMoneyView from '@/components/dialogs/custom-money-dialog-view.vue'
import LimitDialogView from '@/components/dialogs/limit-dialog-view.vue'
import SettingView from '@/components/dialogs/setting-dialog-view.vue'
import ChangePasswordView from '@/components/dialogs/change-pass-view.vue'
import BetHistory from '@/components/dialogs/history-view.vue'
import PopAlertView from '@/components/desk/pop-alert.vue'

@Options({
    components: {
        HeaderView,
        DPredictionSumaryView,
        PcBetView,
        PcSwitchTable,
        DCardResultView,
        CustomMoneyView,
        LimitDialogView,
        SettingView,
        ChangePasswordView,
        BetHistory,
        PopAlertView
    }
})
export default class PcDesk extends Mixins(DeskMixin) {}
