
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { BaccaratBetViewMixin } from './baccarat-bet-view-mixin'
import MobileBetArea from './m-bet-area.vue'

@Options({
    components: {
        MobileBetArea
    }
})
export default class MobileBaccaratBetView extends Mixins(
    BaccaratBetViewMixin
) {}
