
import { ResponseData } from '@/datamodel/response-data'
import { PopAlertModel } from '@/datamodel/types'
import { DTBetParams } from '@/datamodel/urlparams/DTBetParams'
import { BetError, BET_TYPE, EventBuss } from '@/utils/constants'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { BetViewBaseMixin } from './bet-view-mixin'
import MobileBetArea from './m-bet-area.vue'

@Options({
    components: {
        MobileBetArea
    }
})
export default class MobileDragonTigerBetView extends Mixins(BetViewBaseMixin) {
    redConfirmedTemp = 0
    blueConfirmedTemp = 0
    greenConfirmedTemp = 0

    message = ''
    showMessage = false

    @Action('placeDragontigerBet') placeDragontigerBet!: (
        model: DTBetParams
    ) => Promise<ResponseData>

    OnPlaceBet(betType: number): void {
        if (this.chipValue <= 0) return

        const toBetAmount = this.CheckIfValidBet(this.chipValue)

        if (toBetAmount > 0) {
            this.selectedBetArea = betType

            switch (betType) {
                case BET_TYPE.TIGER: {
                    this.redTemp += toBetAmount

                    const _rTotalR =
                        this.redTemp + this.redConfirmed + this.redConfirmedTemp
                    const _bTotalR =
                        this.blueTemp +
                        this.blueConfrimed +
                        this.blueConfirmedTemp

                    const _maxR = this.deskLimit.Max || 0

                    if (Math.abs(_rTotalR - _bTotalR) > _maxR) {
                        this.toastCommonError(BetError.ABOVE_MAXIMUM)

                        this.redTemp = _bTotalR + _maxR
                    }

                    if (this.redTemp > 0) this.selectedBetArea = betType
                    break
                }
                case BET_TYPE.DRAGON: {
                    this.blueTemp += toBetAmount

                    const _rTotalB =
                        this.redTemp + this.redConfirmed + this.redConfirmedTemp
                    const _bTotalB =
                        this.blueTemp +
                        this.blueConfrimed +
                        this.blueConfirmedTemp

                    const _maxB = this.deskLimit.Max || 0

                    if (Math.abs(_rTotalB - _bTotalB) > _maxB) {
                        this.toastCommonError(BetError.ABOVE_MAXIMUM)

                        this.blueTemp = _rTotalB + _maxB
                    }

                    if (this.blueTemp > 0) this.selectedBetArea = betType
                    break
                }
                case BET_TYPE.DT_TIE: {
                    this.greenTemp += toBetAmount
                    const _gTotal =
                        this.greenTemp +
                        this.greenConfrimed +
                        this.greenConfirmedTemp
                    const _gMax = this.deskLimit.TieMaxBet || 0

                    if (_gTotal > _gMax) {
                        this.toastCommonError(BetError.ABOVE_MAXIMUM)
                        this.greenTemp =
                            _gMax -
                            this.greenConfirmedTemp -
                            this.greenConfrimed
                    }

                    if (this.greenTemp > 0) this.selectedBetArea = betType
                    break
                }
            }

            const totalTemp = this.greenTemp + this.redTemp + this.blueTemp
            this.$emit('on-bet-change', totalTemp)
        }
    }

    toastCommonError(_error: string): void {
        if (this.deskId !== -1) {
            this.PopMessage(_error)
        } else {
            const popError: PopAlertModel = {
                type: 0,
                header: '',
                message: _error,
                color: ''
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
        }
    }

    PopMessage(msg: string): void {
        this.message = msg
        this.showMessage = true

        setTimeout(() => {
            this.showMessage = false
            this.message = ''
        }, 1000)
    }

    OnCancelBetTemp(): void {
        this.redTemp = 0
        this.blueTemp = 0
        this.greenTemp = 0
        this.selectedBetArea = -1
        this.$emit('on-bet-change', 0)
    }

    OnConfirmBet(): void {
        const popError: PopAlertModel = {
            type: 0,
            header: '',
            message: '',
            color: ''
        }

        let betError = this.checkRedAndBlueLimit()

        if (betError !== BetError.BET_OK) {
            popError.message = betError
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
            return
        }

        betError = this.checkGreenLimit()

        if (betError !== BetError.BET_OK) {
            popError.message = betError
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
            return
        }

        // everything is passed here call bet
        this.sendBetRequest()
    }

    CheckIfValidBet(tempBet: number): number {
        const total = this.redTemp + this.blueTemp + this.greenTemp + tempBet

        if (total <= this.betCoin) return tempBet
        else {
            const popError: PopAlertModel = {
                type: 0,
                header: '',
                message: BetError.BALANCE_SHORT,
                color: ''
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
            const finalTemp = this.betCoin - (total - tempBet)
            if (finalTemp > 0) return finalTemp
            return 0
        }
    }

    checkRedAndBlueLimit(): string {
        const redTotal = this.redTemp + this.redConfirmed
        const blueTotal = this.blueTemp + this.blueConfrimed

        if (
            (this.redTemp > 0 && redTotal < (this.tableLimit.Min as number)) ||
            (this.blueTemp > 0 && blueTotal < (this.tableLimit.Min as number))
        )
            return BetError.BELOW_MINIMUM

        const differenceTotal = Math.abs(redTotal - blueTotal)
        if (differenceTotal > (this.tableLimit.Max as number))
            return BetError.ABOVE_MAXIMUM

        return BetError.BET_OK
    }

    checkGreenLimit(): string {
        const greenTotal = this.greenTemp + this.greenConfrimed

        if (
            this.greenTemp > 0 &&
            greenTotal < (this.tableLimit.TieMinBet as number)
        )
            return BetError.BELOW_MINIMUM

        if (greenTotal > (this.tableLimit.TieMaxBet as number))
            return BetError.ABOVE_MAXIMUM
        return BetError.BET_OK
    }

    sendBetRequest(): void {
        if (this.hasPendingRequest) return
        this.hasPendingRequest = true
        const popError: PopAlertModel = {
            type: 0,
            header: '',
            message: '',
            color: ''
        }

        let _deskId = -1
        if (this.desk && this.desk.deskId) {
            _deskId = this.desk.deskId
        }

        if (_deskId !== -1) _deskId = this.deskId

        const model = new DTBetParams(
            this.sid,
            this.uid,
            this.desk.deskId || 0,
            this.redTemp,
            this.blueTemp,
            this.greenTemp,
            this.deskLimit.Id || 0
        )

        this.showLoading()

        this.placeDragontigerBet(model)
            .then((response: ResponseData) => {
                // if (this.loadingIsVisible) this.hideLoading()
                this.hideLoading()
                this.hasPendingRequest = false
                if (response.data) {
                    const arrInfo = response.data.split('#')

                    if (response.data.includes('xzok')) {
                        audioPlayer.Play(SOUNDKEY.BET_SUCCESS)
                        this.setBetCoin(parseInt(arrInfo[1]))
                        popError.message = '下注成功'
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)

                        // add the tempbet to confirm bet
                        this.redConfirmedTemp += this.redTemp
                        this.blueConfirmedTemp += this.blueTemp
                        this.greenConfirmedTemp += this.greenTemp
                        this.$emit('bet-success')
                        this.OnCancelBetTemp()
                    } else {
                        popError.message = arrInfo[1]
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
                    }
                }
            })
            .catch(err => {
                // if (this.loadingIsVisible) this.hideLoading()
                this.hideLoading()
                this.hasPendingRequest = false
                popError.message = err
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.emitter.emit(EventBuss.SHOW_POP_ALERT, popError)
            })
    }

    ResetConfirmedTemp(): void {
        this.redConfirmedTemp = 0
        this.blueConfirmedTemp = 0
        this.greenConfirmedTemp = 0
    }

    get redFinal(): number {
        return this.redConfirmed + this.redConfirmedTemp
    }

    get blueFinal(): number {
        return this.blueConfrimed + this.blueConfirmedTemp
    }

    get greenFinal(): number {
        return this.greenConfrimed + this.greenConfirmedTemp
    }
}
