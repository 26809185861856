
import { Device } from '@/store/types'
import { Options } from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { CardResultMixin } from './cardresultmixin'

@Options({})
export default class MobileCardResultView extends Mixins(CardResultMixin) {
    @Getter('device') device!: Device

    isLandscape = false

    @Watch('device', { immediate: true, deep: true })
    OnChangesOnDevice(val: Device): void {
        this.isLandscape = val.orientation === 'landscape'
    }
}
