
import { Options } from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { DragonTigerBetViewMixin } from './dragon-tiger-bet-view-mixin'
import PcBetArea from './d-bet-area.vue'

@Options({
    components: {
        PcBetArea
    }
})
export default class PcDragonTigerBetView extends Mixins(
    DragonTigerBetViewMixin
) {}
